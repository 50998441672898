import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Advice from './MainPage/Advice.js';
import MainImage from './MainPage/MainImage.js';
import Alert from './MainPage/Alert.js';
import About from './MainPage/About.js';
import OnlineServices from './MainPage/OnlineServices.js';
import Services from './MainPage/Services.js';
import Location from './MainPage/Location.js';

export default function MainPage({ content, servicesContent }) {

    const [adviceResponse, setAdviceResponse] = useState(null);
    const [advices, setAdvices] = useState(null);
    const [showAdvices, setShowAdvices] = useState(false);

    function getAdvices() {
        fetch("https://admin.3ripatosdeminas.com.br/api/advices")
            .then(response => response.json())
            .then(response => {
                setAdviceResponse(response);
            });
    }

    useEffect(() => {
        getAdvices();
    }, []);

    useEffect(() => {
        if (adviceResponse && adviceResponse.all_advices.length > 0) {

            setAdvices(adviceResponse.all_advices);

            if (localStorage.getItem('3ri-last-verified-advices')) {
                const lastVerifiedLocalStorage = parseInt(localStorage.getItem('3ri-last-verified-advices').substring(0, 10));    
                const lastUploadedAdvice = adviceResponse.last_upload;

                if (lastVerifiedLocalStorage < lastUploadedAdvice) {
                    setShowAdvices(true);
                }
            } else {
                setShowAdvices(true);
            }

        }

    }, [adviceResponse]);

    return (
        <>
            {/* {
                (showAdvices && advices) && 
                    <Advice 
                        setShowAdvices={ setShowAdvices }
                        advices={ advices }
                    ></Advice>
            } */}
            <MainImage content={ content.main_content }></MainImage>
            <Alert content={ content.main_content.alert_message }></Alert>
            <About content={ content.about_content }></About>
            <OnlineServices content={ content.online_services_content }></OnlineServices>
            <Services content={ servicesContent }></Services>
            <Location content={ content.location_content } contactInfo={ content.info_content }></Location>
            <Outlet></Outlet>
        </>
    );
}