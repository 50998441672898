import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header.js';
import NeighborhoodMap from './Components/NeighborhoodMap.js';
import MainPage from './Components/MainPage.js';
import AboutUs from './Components/AboutUs.js';
import Services from './Components/Services.js';
import Faq from './Components/Faq.js';
import Scheduling from './Components/Scheduling.js';
import QueryRecords from './Components/QueryRecords.js';
import UsefulLinks from './Components/UsefulLinks.js';
import Contact from './Components/Contact.js';
import Lgpd from './Components/Lgpd.js';
import Table from './Components/Table.js';
import Footer from './Components/Footer.js';

export default function App() {

    const [content, setContent] = useState();
    const [showNeighborhoodMap, setShowNeighborhoodMap] = useState(false);

    useEffect(() => {
        fetch("https://cdn.3ripatosdeminas.com.br/json/UniqueContent.json")
            .then(data => data.json())
            .then(data => {
                setContent(data);
            });
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('3ri-division-agreement')) {
            setShowNeighborhoodMap(true);
        }
    }, []);

    return (
        <>
            {
            content && (
                <BrowserRouter>
                    <Header contactInfo={ content.main_page_content.info_content } />
                    {
                        showNeighborhoodMap && <NeighborhoodMap setShowNeighborhoodMap={ setShowNeighborhoodMap } />
                    }
                    
                    <Routes>
                        <Route 
                            path='/' 
                            element={<MainPage content={ content.main_page_content } 
                            servicesContent={ content.services_content } />} 
                        />
                        <Route 
                            path='/sobre-nos' 
                            element={ <AboutUs content={ content.about_us_content } /> } 
                        />
                        <Route 
                            path='/servicos' 
                            element={ <Services content={ content.services_content } /> } 
                        /> | {" "}
                        <Route 
                            path='/perguntas-frequentes' 
                            element={ <Faq content={ content.faq_content } /> } 
                        />
                        <Route 
                            path='/links-uteis' 
                            element={ <UsefulLinks content={ content.usefuls_links_content } /> } 
                        />
                        <Route 
                            path='/contato' 
                            element={ <Contact content={ content.contact_content }/> } 
                        />
                        <Route 
                            path='/politica-de-privacidade' 
                            element={ <Lgpd content={ content.lgpd_content }/> }
                        />
                        <Route 
                            path='/agendamento' 
                            element={ <Scheduling /> } 
                        />
                        <Route 
                            path='/consultar-registro' 
                            element={ <QueryRecords /> } 
                        />
                        <Route 
                            path='/tabela-emolumentos' 
                            element={ <Table /> } 
                        />
                    </Routes>

                    <Footer 
                        contactInfo={ content.main_page_content.info_content } 
                        usefulLinks={ content.usefuls_links_content.useful_links }
                    />
                    
                </BrowserRouter>
                )
            }
        </>
    );
}