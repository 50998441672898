import footerLogo from './../assets/images/logo-white.png';
import {Link} from 'react-router-dom';

export default function Footer({ contactInfo, usefulLinks }) {

    return (
        <footer className="py-4 px-4 py-lg-5">
            <div className="container-fluid container-md my-lg-3">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <img src={ footerLogo } alt="" className="footer-logo" />
                    </div>
                    <div className="col-12 col-lg-10 offset-lg-1 mt-5">
                        <div className="row text-center text-lg-start">
                            <div className="col-12 col-sm-6 order-1 footer-list mb-4">
                                <h5>Mapa do Site</h5>
                                <ul>
                                    <li><Link to='/'>Início</Link></li>
                                    <li><Link to='/sobre-nos'>Sobre Nós</Link></li>
                                    <li><Link to='/servicos'>Serviços</Link></li>
                                    <li><Link to='/perguntas-frequentes'>Perguntas Frequentes</Link></li>
                                    <li><Link to='/links-uteis'>Links Úteis</Link></li>
                                    <li><Link to='/contato'>Contato</Link></li>
                                    <li><Link to='/politica-de-privacidade'>Política de Privacidade</Link></li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 order-2 footer-list mb-4">
                                <h5>Links Úteis</h5>
                                <ul>
                                    {
                                        usefulLinks.map((item, index) => {
                                            return (
                                                <li key={ index }>
                                                    <a href={ item.link_url } target="_blank" rel="noreferrer">
                                                        { item.link_title }
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="col-12 order-4 footer-list mb-4">
                                <h5 className="w-100 text-center">Localização</h5>
                                <ul className="w-100 pe-0 text-center">
                                    <li className="w-100">R. Maj. Gote, 1022 - Centro, Patos de Minas - MG, 38700-001</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="footer-info text-center mt-4 mt-lg-5">
                        <h5>Cartório de 3° Registro de Imóveis de Patos de Minas</h5>
                        <p className="text-center">&copy; Copyright { (new Date()).getFullYear() } - Todos os Direitos Reservados</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}