import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/css/custom.css';

import React from 'react';
import { render } from 'react-dom';
import App from './App.js';


const root = document.getElementById('root');

render(
    <App/ >
  , root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
