import {Link} from 'react-router-dom';
import DOMPurify from 'dompurify';
import iconSchedule from './../../assets/images/icon-schedule.png';
import iconFolder from './../../assets/images/icon-folder.png';
import iconTable from './../../assets/images/icon-table.png';

export default function OnlineServices({ content }) {

    const sanitizeContent = DOMPurify.sanitize(content.description, { USE_PROFILES: { html: true } });
    const scrollToTop = () => window.scrollTo(0, 0);

    return (
        <>
            <section className="py-5 px-4 text-white request-section" id="atendimento-online">
                <div className="container-fluid container-md my-lg-5">
                    <div className="row align-items-lg-center">
                        <div className="col-12 col-lg-6 offset-lg-1">
                            <h3 className="section-title">
                                { content.title }
                                <div className="section-title-stroke">&nbsp;</div>
                            </h3>
                            <div 
                                className="section-description"
                                dangerouslySetInnerHTML={{ __html: sanitizeContent }}></div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="row mt-5">
                                <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0 mb-4">
                                    <Link to="/agendamento" className="w-100 d-flex justify-content-center" onClick={() => scrollToTop()}>
                                        <button className="request-button">
                                            <div className="hold-icon">
                                                <img src={ iconSchedule } alt="" />    
                                            </div>
                                            <h4>{ content.scheduling_button }</h4>
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0 d-flex justify-content-center mb-4">
                                    <Link to="/consultar-registro" className="w-100 d-flex justify-content-center" onClick={() => scrollToTop()}>
                                        <button className="request-button">
                                            <div className="hold-icon">
                                                <img src={ iconFolder } alt="" />    
                                            </div>
                                            <h4>{ content.query_records_button }</h4>
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0 d-flex justify-content-center mb-4">
                                    <Link to="/tabela-emolumentos" className="w-100 d-flex justify-content-center" onClick={() => scrollToTop()}>
                                        <button className="request-button">
                                            <div className="hold-icon">
                                                <img src={ iconTable } alt="" />    
                                            </div>
                                            <h4>{ content.price_table_button }</h4>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}