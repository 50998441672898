import { useRef } from 'react';

export default function Contact({ content }) {

    const inputName = useRef(null);
    const inputMail = useRef(null);
    const inputSubject = useRef(null);
    const inputMessage = useRef(null);

    async function sendRequest(data) {

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('mail', data.mail);
        formData.append('subject', data.subject);
        formData.append('message', data.message);

        const request = await fetch('https://admin.3ripatosdeminas.com.br/api/contato', {
            method: 'POST',
            body: formData,
        });

        

        if (request.status === 200) {
            const data = await request.json();

            if (data.message === 'successful') {
                alert('Contato enviado com sucesso! Responderemos você em breve!');
                return true;
            }

            alert('Houve um erro de comunicação no envio do e-mail! Contate-nos nos utilizando nossos números de telefone!');
            return false;
        } else {
            alert('Houve um erro de comunicação no envio do e-mail! Contate-nos nos utilizando nossos números de telefone!');
            return false;
        }
    }

    function handleSubmit(event) {

        event.preventDefault();

        const inputGroup = [inputName, inputMail, inputSubject, inputMessage];

        const formValidation = inputGroup.every((item) => {
            if (item.current.value === '') {
                return false;
            }

            if (item.current.value.length < 4) {
                return false;
            }

            return true;
        });

        if (formValidation) {
            const data = {
                'name': inputName.current.value,
                'mail': inputMail.current.value,
                'subject': inputSubject.current.value,
                'message': inputMessage.current.value,
            };

            return sendRequest(data);
        }


    }

    return (
        <section className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.description }
                </p>


                <form name="contactForm" onSubmit={ (e) => handleSubmit(e) }>

                    <div className="row align-items-stretch mt-5 mt-lg-0">

                        <div className="col-12 col-md-10 offset-md-1 col-lg-8  offset-lg-2">

                            <div className="row">

                                <div className="col-12 col-md-6">
                                    <div className="d-flex flex-column p-4 contact-label-input">
                                        <label htmlFor="nome">Nome Completo</label>
                                        <input type="text" placeholder="Nome Completo" id="nome" ref={ inputName } />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="d-flex flex-column p-4 contact-label-input">
                                        <label htmlFor="email">E-mail</label>
                                        <input type="text" placeholder="E-mail"  id="email" ref={ inputMail } />
                                    </div>
                                </div>

                                <div className="col-12 ">
                                    <div className="d-flex flex-column p-4 contact-label-input">
                                        <label htmlFor="assunto">Assunto</label>
                                        <input type="text" placeholder="Assunto"  id="assunto" ref={ inputSubject }/>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex flex-column p-4 contact-label-input">
                                        <label htmlFor="mensagem">Mensagem</label>
                                        <textarea placeholder="Mensagem" id="mensagem" ref={ inputMessage }></textarea>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="d-flex justify-content-center p-4 contact-label-input">
                                        <button type="submit">Enviar Formulário</button>
                                    </div>
                                </div>
                                
                            </div>

                        </div>

                    </div>

                    
                </form>

            </div>
        </section>
    );
}