import DOMPurify from 'dompurify';

export default function Alert({ content }) {
    const sanitizeContent = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });
    return (
        <article className="alert py-4">
            <div className="container-fluid container-md">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center text-center">
                        <h3 dangerouslySetInnerHTML={{ __html: sanitizeContent }}></h3>
                    </div>
                </div>
            </div>
        </article>
    );
}