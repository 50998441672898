import React, { useState, useRef } from 'react';

export default function Contact() {

    const protocolInputRef = useRef();
    const [protocolResult, setProtocolResult] = useState(null);
    
    function sendQueryRecordsRequest() {

        let formData = new FormData();
        formData.append('record_protocol', protocolInputRef.current.value);

        const options = {
            method: 'POST',
            body: formData,
        };

        fetch('https://admin.3ripatosdeminas.com.br/api/record-query', options)
            .then(response => {
                if (response.status === 429) {
                    alert('Por favor, aguarde 1 minuto para enviar uma nova requisição!');
                    return false;
                }
                return response.json()
            })
            .then(response => {
                if (response.message === 'wrong_action_method') {
                    alert('Contacte um administrador!');
                    return false;
                }

                if (response.message === 'not_found') {
                    alert('Protocolo não encontrado!');
                    return false;
                }

                setProtocolResult(response);
            }
        );
    }
    

    function handleSubmit(event) {
        event.preventDefault();

        if (protocolInputRef.current.value.length < 4) {
            alert('Por favor, preencha o protocol corretamente!');
            return false;
        }

        sendQueryRecordsRequest();

    }
    return (
        <section className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        Consulta de Registros
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    Digite o Protocolo do Registro para consultar sua situação.
                </p>
                
                {
                    !protocolResult && 
                    <form onSubmit={ (e) => handleSubmit(e) }>

                        <div className="row align-items-stretch mt-5 mt-lg-0">

                            <div className="col-12 col-md-10 offset-md-1 col-lg-8  offset-lg-2">

                                <div className="row">
                                    <div className="col-12 col-md-6 offset-md-3">
                                        <div className="d-flex justify-content-center flex-column p-4 contact-label-input">
                                            <label htmlFor="">Protocolo</label>
                                            <input 
                                                type="text" 
                                                placeholder="Número do Protocolo" 
                                                ref={ protocolInputRef }
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="d-flex justify-content-center p-4 contact-label-input">
                                            <button type="submit">Procurar Registro</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        
                    </form>
                
                }

                {
                    protocolResult &&
                    <div className='text-center'>
                        <h2 className='fw-bolder mb-4'>Protocolo encontrado!</h2>
                        <h4 className='fs-4'>Nome do Responsável</h4>
                        <h2 className="fw-bolder fs-1 mt-3">{ protocolResult.person_name }</h2>
                        <h3 className='mt-4 fw-bolder'>
                            Status do Pedido
                            {
                                protocolResult.status === 1 ? 
                                    <div className='text-primary fw-bolder record-status record-status'>
                                        Em Andamento
                                    </div>
                                : protocolResult.status === 2 ? 
                                    <div className='text-success fw-bolder record-status record-status'>
                                        Concluído
                                    </div>
                                : 
                                    <div className='text-danger fw-bolder record-status record-status'>
                                        Cancelado
                                    </div>  
                            }
                        </h3>
                    </div>
                }

            </div>
        </section>
    );
}