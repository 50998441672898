import DOMPurify from 'dompurify';

export default function AboutUs({ content }) {

    const sanitizeContent = DOMPurify.sanitize(content.text, { USE_PROFILES: { html: true } });

    return (
        <main className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">

                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.description }
                </p>

                <div className="row align-items-md-center">
                    <div className="col-12 col-md-10 offset-md-1 d-flex justify-content-md-center mt-5">
                        <div className="hold-about-us-image">
                            <img src={ content.image } alt="" />
                        </div>
                    </div>
                    <div 
                        className="col-12 col-md-10 offset-md-1 section-description"
                        dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                    ></div>
                    
                </div>
            </div>
        </main>
    );
}