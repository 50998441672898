import React, { useState } from "react";

export default function Services({ content }) {

    const services = content.services;
    const [choosedService, setChoosedService] = useState(services[0]);

    const [selectedService, setSelectedService] = useState(null);

    useState(() => {
        const url = new URL(window.location.href);
        const selectedService = url.searchParams.get('servico');

        if (selectedService === null || !selectedService) {
            return false;
        }

        setSelectedService(selectedService);

        let findService = services.findIndex((item) => {
            return item.service_title === selectedService;
        });

        if (findService > -1) setChoosedService(services[findService]);

    }, [selectedService]);

    return (
        <section className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.content }
                </p>

                <div className="row mt-5 mt-lg-0">

                    <div className="col-12 col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-3 service-page-selector">
                                <h4>Selecione um Serviço</h4>
                                <ul>
                                    {
                                        services.map((item, index) => {
                                            return (
                                                <li 
                                                    key={ index }
                                                    onClick={ () => setChoosedService( services[index] ) }
                                                    className={ 
                                                        choosedService.service_title === services[index].service_title ? 'active' : '' 
                                                    }
                                                >{ item.service_title }</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="col-12 col-md ps-md-5 service-page-info">
                                <h4>{ choosedService.service_title }</h4>
                                <span>&nbps;</span>
                                <div className="row align-items-start">
                                    <div className="service-page-image d-flex justify-content-center">
                                        <img src={ choosedService.service_image } alt="" className="w-100"/>
                                    </div>
                                    <div className="col-12 col-md">
                                        <p>{ choosedService.service_description }</p>
                                        <a href="https://www.crimg.com.br/#/servicos#topo" className="btn text-white mt-4" target="__blank">
                                            Acessar Online
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    );
}