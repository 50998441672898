export default function About({ content }) {
    return (
        <main className="py-5 px-4 d-flex flex-column align-items-center">
            <div className="container-fluid container-md my-lg-5">
                <div className="row align-items-md-center">
                    <div className="col-12 col-md-4 col-lg-5 d-none order-md-2 d-md-flex justify-content-md-center">
                        <img src={ content.image } alt="" className="map-patos" />
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 offset-lg-1 order-md-1">
                        <h3 className="section-title">
                            { content.title }
                            <div className="section-title-stroke">&nbsp;</div>
                        </h3>
                        <p className="section-description">
                            { content.content }
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}