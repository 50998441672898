import { useState } from 'react';

export default function NeighborhoodMap({ setShowNeighborhoodMap }) {

    const [location, setLocation] = useState(null);

    const regions = [
        {
            title: 'Região Leste',
            neighborhoods: [
                'Afonso Queiroz', 'Antonio Caixeta', 'Belvedere',
                'Boa Vista', 'Cidade Jardim', 'Eldorado', 'Jardim Paronâmico',
                'Jardim Recanto', 'Morada da Serra', 'Sebastião Amorim'
            ]
        },
        {   
            title: 'Região Sudeste',
            neighborhoods: [
                'Campos Eliseos', 'Distrito Industrial I', 'Distrito Industrial II',
                'Jardim Céu Azul', 'Jardim dos Andrades', 'Jardim Pelluzo', 'Residencial Gramado'
            ]
        },
        {
            title: 'Região Sul',
            neighborhoods: [
                'Cidade Nova', 'Ipanema', 'Jardim Paulistano', 'Planalto',
            ]
        },
        {
            title: 'Outras Regiões',
            neighborhoods: [
                'Areado', 'Bonsucesso', 'Major Porto', 'Pindaibas', 'Bom Sucesso',
            ]
        }
    ];

    function confirmAgreement() {
        if (location) {
            localStorage.setItem('3ri-division-agreement', location);
            setShowNeighborhoodMap(false);
        }
    }

    return (
        <div className="neighborhood">
            <div className="container">
                <div className="row neighborhood-infos gx-0 position-relative">

                    <div className="hold-image"></div>
                    <div className="p-5 hold-infos">
                        <h3>Atenção! </h3>
                        <h4>A partir da Resolução nº 975/2021, exarada pelo Desembargador GILSON SOARES LEMES, Presidente do Tribunal de Justiça de Minas Gerais, criou-se o 2° e 3° Registo de Imóveis de Patos de Minas.</h4>
                        <h4 className="fw-bolder">Selecione a região de sua residência</h4>

                        <div className="row my-4">
                            <div className="col-12">
                                    {
                                        regions.map((item, index) => {
                                            return (
                                                <div className="col-12" key={ index }>
                                                    <h5>{ item.title }</h5>
                                                    <ul>
                                                        {
                                                            item.neighborhoods.map((item, index) => {
                                                                return (
                                                                    <li 
                                                                        key={ index } 
                                                                        onClick={ () => setLocation(item) }
                                                                        className={`${ location === item ? 'active' : '' }`}
                                                                    >
                                                                        { item }
                                                                    </li>
                                                                );
                                                            })
                                                                
                                                        }
                                                    </ul>
                                                </div>
                                            );
                                        })
                                    }
                            </div>
                        </div>
                        <div className="row hold-button-region">
                            <button 
                                className={`confirm-neighborhood-button ${ location ? 'active' : '' }`}
                                onClick={ () => confirmAgreement() }
                                disabled={ location ? false : true }
                            >
                                { location ?  'Confirmar Região' : 'Selecione uma Região para Continuar'}
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}