import { Link } from "react-router-dom";

export default function Services({ content }) {

    const services = content.services;

    function scrollToTop () { window.scrollTo(0, 0); }

    return (
        <section className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.content }
                </p>

                <div className="row mt-4 mt-lg-0 justify-content-center">

                    {
                        services.map((item, index) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={ index }>
                                    <Link 
                                        to={{
                                            pathname: '/servicos',
                                            search: `?servico=${ encodeURI(item.service_title) }` ,
                                        }}
                                        onClick={ () => scrollToTop() }
                                    >
                                        <div className="service-block mb-4">
                                            <div className="service-image">
                                                <img src={ item.service_image } alt="" />
                                            </div>
                                            <div className="service-info">
                                                <h4>{ item.service_title }</h4>
                                                <p>
                                                { 
                                                    item.service_description.length > 150 ?
                                                        `${item.service_description.substring(0, 150)}...` :
                                                        item.service_description
                                                }
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })
                    }
                    

                    
                </div>
            </div>
        </section>
    );
}