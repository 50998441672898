import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import logo from './../assets/images/logo.jpg';
import iconMenu from './../assets/images/menu.png';

import iconPhone from './../assets/images/icon-phone.png';
import iconWatch from './../assets/images/icon-watch.png';
import iconMap from './../assets/images/icon-map.png';
import iconWhatsapp from './../assets/images/icon-whatsapp.png';
import iconMail from './../assets/images/icon-mail.png';


export default function Header({ contactInfo }) {

    const [mobileMenu, setMobileMenu] = useState(false);    

    function changeHash() {
        const locationHash = (window.location.hash);
        if (locationHash === '#menu' && mobileMenu) {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(null, null, ' ');
        } else {  
            window.location.hash = '#menu';
        }
    }

    window.addEventListener('hashchange', () => {
        const locationHash = (window.location.hash);
        if (locationHash !== '#menu') {
          setMobileMenu(false);
        }
    });

    function scrollToTop () { window.scrollTo(0, 0); }

    return (
        <>
            <header className="py-4 py-lg-5">
                <div className="container-fluid container-md">
                    <div className="row">
                        <div className="col-12 d-flex flex-wrap justify-content-between align-items-center px-5 px-md-0">
                        <img 
                            src={ logo } 
                            alt="logo-3ri" 
                            className={`logo order-0 ${ contactInfo.length > 3 ? 'order-lg-2' : 'order-lg-0' }`} 

                        />
                        {
                            contactInfo.slice(0, 4).map((item, key) => {
                                return (
                                    <div 
                                        className={
                                            `
                                                info-block d-none d-lg-flex align-items-center order-${key}
                                                ${ contactInfo.length > 3 ? 'limited-size-icon' : '' }
                                            `
                                        }
                                        key={ key }
                                    >
                                        <img 
                                            src={ 
                                                item.icon === 'phone' ? iconPhone :
                                                item.icon === 'mail' ? iconMail :
                                                item.icon === 'whatsapp' ? iconWhatsapp :
                                                item.icon === 'address' ? iconMap :
                                                iconWatch
                                            } 
                                            alt="" 

                                        />
                                        <div className="d-flex flex-column">
                                            <p>{ item.text }</p>
                                            <h4
                                                style={ item.icon === 'mail' ? { fontSize: '1.4rem' } : { fontSize: 'initial' } }
                                            >
                                                { item.content }
                                            </h4>
                                        </div>
                                    </div>
                                );
                            })
                        }

                            

                            
                            <button 
                                className="mobile-menu-button d-lg-none" 
                                onClick={() => { setMobileMenu(!mobileMenu); changeHash(); } }
                            >
                                <img src={ iconMenu } alt="" />
                            </button>

                            {
                                mobileMenu &&
                                <div className="col-12 mobile-menu d-flex d-lg-none flex-column mt-4">
                                    <div className="row flex-column menu-collection">
                                        <ul>
                                            <Link to='/' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Início</li>
                                            </Link>
                                            <Link to='/sobre-nos' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Sobre Nós</li>
                                            </Link>
                                            <Link to='/servicos' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Serviços</li>
                                            </Link>
                                            <Link to='/perguntas-frequentes' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Perguntas Frequentes</li>
                                            </Link>
                                            <Link to='/links-uteis' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Links Úteis</li>
                                            </Link>
                                            <Link to='/contato' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Contato</li>
                                            </Link>
                                            <Link to='/politica-de-privacidade' onClick={() => { setMobileMenu(false) ; scrollToTop(); } }>
                                                <li>Política de Privacidade</li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </header>

            <nav className="d-none d-lg-block">
                <div className="container menu-collection">
                    <ul className="py-2 py-lg-3 m-0 d-flex justify-content-around text-white nav-menu">
                        <li>
                            <Link to='./' onClick={() => scrollToTop() }>Início</Link>
                        </li>
                        <li>
                            <Link to='./sobre-nos' onClick={() => scrollToTop() }>Sobre Nós</Link>
                        </li>
                        <li>
                            <Link to='./servicos' onClick={() => scrollToTop() }>Serviços</Link>
                        </li>
                        <li>
                            <Link to='./perguntas-frequentes' onClick={() => scrollToTop() }>Perguntas Frequentes</Link>
                        </li>
                        <li>
                            <Link to='./links-uteis' onClick={() => scrollToTop() }>Links Úteis</Link>
                        </li>
                        <li>
                            <Link to='./contato' onClick={() => scrollToTop() }>Contato</Link>
                        </li>
                        <li>
                            <Link to='/politica-de-privacidade' onClick={() => scrollToTop() }>Politica de Privacidade</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}