export default function Table() {
    return (
        <section className="py-5 px-4 service">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        Tabela de Emolumentos
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    Custos de emissão de documentos e serviços cartoriais
                </p>

                <div className="row mt-5 mt-lg-0">
                    <h3 className="text-center fw-bold">Clique para selecionar uma tabela</h3>
                    <div className="col-12">
                        <div className="p-3 contact-label-input">
                            <a href="https://www.serjus.com.br/arqdoc/2022/Tabela%204.pdf" target="__blank" className="w-100">
                                <button type="button" className="col-12 col-md-6 offset-md-3">Tabela 4 - Registro de Imóveis</button>
                            </a>
                        </div>
                        <div className="p-3 contact-label-input">
                            <a href="https://www.serjus.com.br/arqdoc/2022/Tabela%208.pdf" target="__blank" className="w-100">
                                <button type="button" className="col-12 col-md-6 offset-md-3">Tabela 8 – Atos Comuns a Notários a Registradores</button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}