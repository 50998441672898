export default function UsefulLinks({ content }) {

    const usefulLinks = content.useful_links;

    return (
        <section className="py-5 px-4 service dark-gray-background">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.description }
                </p>

                <div className="row align-items-stretch mt-5 mt-lg-0">
                    {
                        usefulLinks.map((item, index) => {
                            return (
                                <div className="col-12 col-md-6 faq" key={ index }>
                                    <a href={ item.link_url } target="_blank" rel="noreferrer">
                                        <div className="d-flex flex-column p-4">
                                            <h4 className="pb-3">{ item.link_title }</h4>
                                            <p>
                                                <a href={ item.link_url } target="_blank" rel="noreferrer">
                                                    { item.link_url }
                                                </a>
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>

            </div>
        </section>
    );
}