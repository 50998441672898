export default function MainImage({ content }) {
    return (
        <article className="main-image py-5 px-3 position-relative d-flex justify-content-center align-items-center flex-column text-center">
            <h1>{ content.title }</h1>
            <h2 className="mt-1">{ content.text }</h2>
            <div className="header-background d-flex justify-content-center align-items-center">
                <img src={ content.image } alt="" />
            </div>
        </article>
    );
}