import iconPhone from './../../assets/images/icon-phone.png';
import iconWatch from './../../assets/images/icon-watch.png';
import iconMap from './../../assets/images/icon-map.png';
import iconWhatsapp from './../../assets/images/icon-whatsapp.png';
import iconMail from './../../assets/images/icon-mail.png';

export default function Location({ content, contactInfo }) {

    return (
        <section className="py-5 py-lg-0 px-4">
            <div className="container-fluid container-md">
                <div className="row align-items-lg-center my-lg-5">
                    <div className="col-12 col-lg-6">
                        <h3 className="section-title">
                            { content.title }
                            <div className="section-title-stroke">&nbsp;</div>
                        </h3>
                        <p className="section-description">{ content.description }</p>
                        <div className="row justify-content-around align-items-md-center mt-5 mt-md-2 mt-lg-5">
                            <div className="col-12 col-md-5 col-lg-12">
                                <div className="row">

                                    {
                                        contactInfo.map((item, index) => {
                                            return (
                                                <div 
                                                    className="col-6 col-md-12 col-lg-6 mb-4 d-flex"
                                                    key={ index }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="location-icon me-3">
                                                            <img 
                                                                src={ 
                                                                    item.icon === 'phone' ? iconPhone :
                                                                    item.icon === 'mail' ? iconMail :
                                                                    item.icon === 'whatsapp' ? iconWhatsapp :
                                                                    item.icon === 'address' ? iconMap :
                                                                    iconWatch
                                                                } 
                                                                alt="" 

                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column location-info">
                                                            <small>{ item.text }</small>
                                                            <h5>{ item.content }</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    

                                    

                                </div>
                            </div>
                            <div className="col-12 col-md-7 d-lg-none mt-4">
                                <iframe 
                                    title="mapa"
                                    width="100%" 
                                    height="450" 
                                    scrolling="no" 
                                    src={ content.link_map }
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block mt-4">
                        <iframe 
                            title="mapa"
                            width="100%" 
                            height="500" 
                            scrolling="no" 
                            src={ content.link_map }
                        >
                        </iframe>
                    </div>
                </div>
            </div>
        </section>
    );
}