import React, { useState, useRef } from 'react';

export default function Form({ dates, hours, setScheduleStatus })
{
    const nameInputRef = useRef();
    const phoneInputRef = useRef();

    const [choosedHour, setChoosedHour] = useState(null);
    const [formatedDay, setFormatedDay] = useState(null);


    function sendScheduleRequest(userData) {
        const formatedData = `${ formatedDay } ${choosedHour}:00`;
        
        let formData = new FormData();
        formData.append('schedule_person_name', nameInputRef.current.value);
        formData.append('schedule_phone', phoneInputRef.current.value);
        formData.append('schedule_date', formatedData);

        const options = {
            method: 'POST',
            body: formData,
        };

        fetch('https://admin.3ripatosdeminas.com.br/api/scheduling', options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.message === 'wrong_action_method') {
                    alert('Contacte um administrador!');
                    return false;
                }

                if (response.message === 'already_exists') {
                    alert('Esse horário já está reservado!');
                    return false;
                }

                if (
                    response.message === 'not_allowed_period' ||
                    response.message === 'not_allowed_day' ||
                    response.message === 'not_alowed_hour' ||
                    response.message === 'not_allowed_minutes' 
                ) {
                    alert('A data escolhida não é permitida!');
                }

                if (response.message === 'unauthorized') {
                    alert('Atenção, contacte um administrador!');
                    return false;
                }


                if (response.message === 'success') {
                    setScheduleStatus(true);
                }

            });
    }

    function phoneMask(e) {
        e.preventDefault();
        let val = phoneInputRef.current.value;
        let keyCode = parseInt(e.key);

        if (isNaN(keyCode) || val.length >= 14) {
            return false;
        } else {
 
            if (val.length === 0) {
                phoneInputRef.current.value += '('
            }

            if (val.length === 3) {
                phoneInputRef.current.value += ') '
            }

            // if (val.length === 13) {
            //     if (phoneInputRef.current.value.substr(5, 1) !== '9') {
            //         return false;
            //     }
            // }

            phoneInputRef.current.value += keyCode;

        }
    }

    function phoneFix(e) {
        let val = phoneInputRef.current.value;

        if (val.length === 10) {
            phoneInputRef.current.value = `(${ val.substr(0, 2) }) ${ val.substr(2, 4) }-${ val.substr(6, 4) }`;
        }

        if (val.length === 11) {
            phoneInputRef.current.value = `(${ val.substr(0, 2) }) ${ val.substr(2, 5) }-${ val.substr(7, 4) }`;
        }

        if (val.length === 13) {
            let actualValue = phoneInputRef.current.value;
            phoneInputRef.current.value = actualValue.substr(0, 9);
            phoneInputRef.current.value += `-${ actualValue.substr(9, 4) }`;
        }

        if (val.length === 14) {
            let actualValue = phoneInputRef.current.value;
            phoneInputRef.current.value = actualValue.substr(0, 10);
            phoneInputRef.current.value += `-${ actualValue.substr(10, 5) }`;
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        let data = {
            'name': nameInputRef.current.value,
            'phone': phoneInputRef.current.value,
        };

        if (!data.name || !data.phone) {
            alert ('Atenção, preencha todos os dados!');
            return false;
        }

        if (!formatedDay || !choosedHour) {
            alert ('Atenção, selecione a data e a hora desejada!');
            return false;
        }

        if (data.name.length < 5) {
            alert ('Preencha o nome corretamente!');
            return false;
        }
        if (data.phone.length < 14) {
            alert ('Preencha o telefone corretamente');
            return false;
        }

        sendScheduleRequest(data);
    }

    return (
        <form onSubmit={ (e) => handleSubmit(e) }>

            <div className="row align-items-stretch mt-5 mt-lg-0">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8  offset-lg-2">

                    <div className="row">

                        <div className="col-12 col-md-6">
                            <div className="d-flex flex-column p-4 contact-label-input">
                                <label htmlFor="">Nome Completo</label>
                                <input 
                                    type="text" 
                                    placeholder="Nome Completo" 
                                    ref={ nameInputRef }
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="d-flex flex-column p-4 contact-label-input">
                                <label htmlFor="">Telefone</label>
                                <input 
                                    type="tel" 
                                    placeholder="Ex: (34) 999999999" 
                                    onKeyPress={(e) => phoneMask(e)}
                                    onBlur={(e) => phoneFix(e)}
                                    ref={ phoneInputRef }
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="d-flex flex-column p-4 contact-label-input">
                                <label htmlFor="">Dia do Atendimento</label>
                                <select onChange={ (e) => { setFormatedDay(e.target.value) } } >
                                    <option value="">Escolha uma Data</option>
                                    {
                                        dates &&
                                        dates.map((item, index) => {
                                            return (
                                                <option
                                                    value={ item.composedDate }
                                                    key={ index }
                                                >
                                                    { item.dayOfWeek } - { item.visualDate }
                                                </option>
                                            );
                                        })
                                    }
                                    
                                </select>
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="d-flex flex-column p-4 contact-label-input">
                                <label htmlFor="">Horário do Atendimento</label>
                                <select onChange={ (e) => { setChoosedHour(e.target.value) } }>
                                    <option value="">Escolha um horário</option>
                                    {
                                        hours &&
                                        hours.map((item, index) => {
                                            return (
                                                <option
                                                    className="schedule-day" 
                                                    value={ item }
                                                    key={ index }
                                                >{item}h</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>


                        <div className="col-12">
                            <div className="d-flex justify-content-center p-4 contact-label-input">
                                <button type="submit">Agendar Horário</button>
                            </div>
                        </div>
                        
                    </div>

                </div>

            </div>

            
        </form>
    );
}