export default function Faq({ content }) {

    const faqs = content.faqs;

    return (
        <section className="py-5 px-4 service dark-gray-background">
            <div className="container-fluid container-md my-lg-5">
                <div className="text-center">
                    <h3 className="section-title">
                        { content.title }
                        <div className="section-title-stroke section-title-stroke-center">&nbsp;</div>
                    </h3>
                </div>

                <p className="section-description text-center service-max-paragragh-size">
                    { content.description }
                </p>

                <div className="row align-items-stretch mt-5 mt-lg-0">
                    {
                        faqs.map((item, index) => {
                            return (
                                <div className="col-12 faq" key={ index }>
                                    <div className="d-flex flex-column px-5 py-5">
                                        <h4 className="pb-3">{ item.faq_title }</h4>
                                        <p>{ item.faq_content }</p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </section>
    );
}